import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

interface PriceRangeFilterProps {
  minPrice?: number;
  maxPrice?: number;
  onMinPriceChange?: (minPrice?: number) => void;
  onMaxPriceChange?: (minPrice?: number) => void;
}

const PriceRangeFilter: React.FC<PriceRangeFilterProps> = ({
  minPrice,
  maxPrice,
  onMinPriceChange,
  onMaxPriceChange,
}) => {
  const { t } = useI18next();
  const handleMinPriceChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onMinPriceChange) {
      onMinPriceChange(parseInt(e.target.value) ?? undefined);
    }
  };

  const handleMaxPriceChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onMaxPriceChange) {
      onMaxPriceChange(parseInt(e.target.value) ?? undefined);
    }
  };

  return (
    <div className="range-filter">
      <div className="range-filter__prefix">&euro;</div>
      <div className="range-filter__input">
        <input
          type="number"
          className="form__input"
          placeholder={t("MINIMUM_SHORT")}
          value={minPrice ?? ""}
          onChange={handleMinPriceChange}
        />
      </div>
      <div className="range-filter__label">{t("TILL")}</div>
      <div className="range-filter__input">
        <input
          type="number"
          className="form__input"
          placeholder={t("MAXIMUM_SHORT")}
          value={maxPrice ?? ""}
          onChange={handleMaxPriceChange}
        />
      </div>
    </div>
  );
};

export default PriceRangeFilter;
