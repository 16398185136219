import React, { useEffect, useContext, useReducer } from "react";
import { useLocation } from "@reach/router";
import { Action, FilterState, toggleTheme } from "./reducer";

interface ProviderProps {
  initialState: FilterState;
  reducer: (state: FilterState, action: Action) => FilterState;
}

const Store = React.createContext<Partial<[FilterState, React.Dispatch<Action>]>>([]);
Store.displayName = "SearchStore";

export const useFilterStore = () => useContext(Store)!;

export const FilterStoreProvider: React.FC<ProviderProps> = ({
  children,
  initialState,
  reducer,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const themeId = params.get("theme");

    if (themeId) {
      dispatch(toggleTheme(themeId, true));
    }
  }, [location]);

  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>;
};
