import React from "react";
import { SortOrder } from "./filter-store/reducer";
import { useI18next } from "gatsby-plugin-react-i18next";

interface SorterProps {
  order?: SortOrder;
  onChange?: (order: SortOrder) => void;
}

const Sorter: React.FC<SorterProps> = ({ order, onChange }) => {
  const { t } = useI18next();
  const handleChange: React.FormEventHandler<HTMLSelectElement> = (e) => {
    if (onChange) {
      onChange(e.currentTarget.value as SortOrder);
    }
  };

  return (
    <div className="dropdown dropdown--transparent">
      <select onChange={handleChange} value={order ?? "departureAsc"}>
        <option value={"departureAsc"}>{t("DEPARTURE_DATE")}</option>
        <option value={"priceDesc"}>{t("PRICE_ASC")}</option>
        <option value={"priceAsc"}>{t("PRICE_DESC")}</option>
      </select>
    </div>
  );
};

export default Sorter;
