import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

interface ProductTypeFilterProps {
  value?: number[];
  onChange?: (productTypes: number[]) => void;
}

const ProductTypeFilter: React.FC<ProductTypeFilterProps> = ({ value, onChange }) => {
  const { t } = useI18next();
  const handleHotelToggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onChange) {
      if (value?.some((x) => x === 3)) {
        onChange(value.filter((x) => x !== 3));
      } else {
        onChange([...(value || []), 3]);
      }
    }
  };

  const handleRoundtripToggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onChange) {
      if (value?.some((x) => x === 1)) {
        onChange(value.filter((x) => x !== 1));
      } else {
        onChange([...(value || []), 1]);
      }
    }
  };

  return (
    <>
      <label className="checkbox checkbox--reverse">
        <input
          type="checkbox"
          name="checkbox-filter"
          id="checkbox-filter"
          className="checkbox__input"
          checked={value?.some((x) => x == 1)}
          onChange={handleRoundtripToggle}
        />
        <span>{t("ROUNDTRIPS")}</span>
      </label>
      <label className="checkbox checkbox--reverse">
        <input
          type="checkbox"
          name="checkbox-filter"
          id="checkbox-filter"
          className="checkbox__input"
          checked={value?.some((x) => x == 3)}
          onChange={handleHotelToggle}
        />
        <span>{t("HOTELS_C")}</span>
      </label>
    </>
  );
};

export default ProductTypeFilter;
