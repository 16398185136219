import {
  TideItemForHotel,
  TideItemForRoundtrip,
  TideItemForTheme,
  TideItemForUsp,
} from "../../types";

import React from "react";
import { graphql } from "gatsby";
import { FilterStoreProvider } from "./filter-store/context";
import { initialFilterState, filterReducer } from "./filter-store/reducer";
import { QSMStoreProvider } from "./qsm-store/context";
import { initialQSMState, qsmReducer } from "./qsm-store/reducer";
import FacetedSearch from "./faceted-search";

interface SearchResultsProps {
  hotels: TideItemForHotel[];
  roundtrips: TideItemForRoundtrip[];
  themes: TideItemForTheme[];
  usps: TideItemForUsp[];
  searchPagePath?: string;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  hotels,
  roundtrips,
  themes,
  usps,
  searchPagePath,
}) => {
  return (
    <QSMStoreProvider initialState={initialQSMState} reducer={qsmReducer}>
      <FilterStoreProvider initialState={initialFilterState} reducer={filterReducer}>
        <FacetedSearch
          hotels={hotels}
          roundtrips={roundtrips}
          themes={themes}
          usps={usps}
          searchPagePath={searchPagePath}
        />
      </FilterStoreProvider>
    </QSMStoreProvider>
  );
};

export default SearchResults;

export const query = graphql`
  fragment searchResults on TideItem {
    id
    ... on TideItemForHotel {
      content {
        general {
          product {
            code
            tideId
            allotmentType
            regimes {
              code
              name
              localizedNames {
                languageCode
                value
              }
            }
            minDuration
            minDurationType
          }
          title
          thumbnailPicture {
            url
          }
          stars
          slug
          themes {
            ...productThemeFragment
          }
          usps {
            ...productUspFragment
          }
          estimatedStay
        }
      }
      ...productPathFragment
    }
    ... on TideItemForRoundtrip {
      content {
        general {
          product {
            code
            tideId
            regimes {
              code
              name
              localizedNames {
                languageCode
                value
              }
            }
            minDuration
            minDurationType
          }
          title
          thumbnailPicture {
            url
          }
          slug
          themes {
            ...productThemeFragment
          }
          usps {
            ...productUspFragment
          }
          estimatedStay
          duration
        }
      }
      ...productPathFragment
    }
  }

  fragment productThemeFragment on TideItemForTheme {
    ... on TideItemForTheme {
      id
      name
      content {
        general {
          title
        }
      }
    }
  }

  fragment productUspFragment on TideItemForUsp {
    ... on TideItemForUsp {
      id
      name
      content {
        general {
          text
        }
      }
    }
  }
`;
