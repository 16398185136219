import { setProduct } from "../qsm-store/reducer";

export type SortOrder = "departureAsc" | "priceAsc" | "priceDesc";

export interface FilterState {
  sortOrder?: SortOrder;
  productTypes: number[];
  ratings: number[];
  themes: string[];
  usps: string[];
  minPrice?: number;
  maxPrice?: number;
  regimeCodes?: string[];
  pendingFilter?: boolean;
}

const actionPrefix = "APP/FILTERS";

export const SET_SORT_ORDER = `${actionPrefix}/SET_SORT_ORDER`;
export const setSortOrder = (sortOrder?: SortOrder) => ({
  type: SET_SORT_ORDER,
  sortOrder,
});

export const SET_PRODUCT_TYPES = `${actionPrefix}/SET_PRODUCT_TYPES`;
export const setProductTypes = (productTypes: number[]) => ({
  type: SET_PRODUCT_TYPES,
  productTypes,
});

export const SET_RATINGS = `${actionPrefix}/SET_RATINGS`;
export const setRatings = (ratings: number[]) => ({
  type: SET_RATINGS,
  ratings,
});

export const TOGGLE_THEME = `${actionPrefix}/TOGGLE_THEME`;
export const toggleTheme = (themeId: string, selected: boolean) => ({
  type: TOGGLE_THEME,
  themeId,
  selected,
});

export const TOGGLE_USP = `${actionPrefix}/TOGGLE_USP`;
export const toggleUsp = (uspId: string, selected: boolean) => ({
  type: TOGGLE_USP,
  uspId,
  selected,
});

export const SET_MIN_PRICE = `${actionPrefix}/SET_MIN_PRICE`;
export const setMinPrice = (minPrice?: number) => ({
  type: SET_MIN_PRICE,
  minPrice,
});

export const SET_MAX_PRICE = `${actionPrefix}/SET_MAX_PRICE`;
export const setMaxPrice = (maxPrice?: number) => ({
  type: SET_MAX_PRICE,
  maxPrice,
});

export const SET_REGIME_CODES = `${actionPrefix}/SET_REGIME_CODES`;
export const setRegimeCodes = (regimeCodes: string[]) => ({
  type: SET_REGIME_CODES,
  regimeCodes,
});

export const SET_PENDING_FILTER = `${actionPrefix}/SET_PENDING_FILTER`;
export const setPendingFilter = (pendingFilter: boolean) => ({
  type: SET_PENDING_FILTER,
  pendingFilter,
});

export const CLEAR_ALL = `${actionPrefix}/CLEAR_ALL`;
export const clearAll = () => ({
  type: CLEAR_ALL,
});

export type Action =
  | ReturnType<typeof setSortOrder>
  | ReturnType<typeof setProductTypes>
  | ReturnType<typeof setRatings>
  | ReturnType<typeof setMinPrice>
  | ReturnType<typeof setMaxPrice>
  | ReturnType<typeof clearAll>
  | ReturnType<typeof toggleTheme>
  | ReturnType<typeof toggleUsp>
  | ReturnType<typeof setRegimeCodes>
  | ReturnType<typeof setPendingFilter>;

export const initialFilterState: FilterState = {
  sortOrder: "priceAsc",
  productTypes: [1, 3],
  ratings: [],
  themes: [],
  usps: [],
  regimeCodes: [],
  minPrice: undefined,
  maxPrice: undefined,
  pendingFilter: false,
};

const runReducer = (state: FilterState, action: Action): FilterState => {
  switch (action.type) {
    case SET_SORT_ORDER:
      return { ...state, sortOrder: (action as ReturnType<typeof setSortOrder>).sortOrder };
    case SET_PRODUCT_TYPES:
      return {
        ...state,
        productTypes: (action as ReturnType<typeof setProductTypes>).productTypes,
      };
    case SET_RATINGS:
      return { ...state, ratings: (action as ReturnType<typeof setRatings>).ratings };
    case TOGGLE_THEME:
      const toggleThemeAction = action as ReturnType<typeof toggleTheme>;
      if (toggleThemeAction.selected) {
        return {
          ...state,
          themes: [...state.themes, toggleThemeAction.themeId].sort(),
        };
      } else {
        const themeIndex = state.themes.indexOf(toggleThemeAction.themeId);
        return {
          ...state,
          themes: [...state.themes.slice(0, themeIndex), ...state.themes.slice(themeIndex + 1)],
        };
      }
    case TOGGLE_USP:
      const toggleUspAction = action as ReturnType<typeof toggleUsp>;
      if (toggleUspAction.selected) {
        return {
          ...state,
          usps: [...state.usps, toggleUspAction.uspId].sort(),
        };
      } else {
        const uspIndex = state.usps.indexOf(toggleUspAction.uspId);
        return {
          ...state,
          usps: [...state.usps.slice(0, uspIndex), ...state.usps.slice(uspIndex + 1)],
        };
      }
    case SET_MIN_PRICE:
      return { ...state, minPrice: (action as ReturnType<typeof setMinPrice>).minPrice };
    case SET_MAX_PRICE:
      return { ...state, maxPrice: (action as ReturnType<typeof setMaxPrice>).maxPrice };
    case SET_REGIME_CODES:
      return { ...state, regimeCodes: (action as ReturnType<typeof setRegimeCodes>).regimeCodes };
    case SET_PENDING_FILTER:
      return {
        ...state,
        pendingFilter: (action as ReturnType<typeof setPendingFilter>).pendingFilter,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ratings: [],
        themes: [],
        usps: [],
        regimeCodes: [],
        minPrice: undefined,
        maxPrice: undefined,
      };
    default:
      return state;
  }
};

export const filterReducer = (
  state: FilterState = initialFilterState,
  action: Action
): FilterState => {
  const newState = runReducer(state, action);

  /*
  console.groupCollapsed(action.type);
  console.info("action:", action);
  console.info("new state:", newState);
  console.info("old state:", state);
  console.groupEnd();
  */

  return newState;
};
