import React, { useState, useEffect } from "react";
import Icon from "../../components/icon";
import { buildClassName } from "../../utils";

interface RatingFilterProps {
  value?: number[];
  onChange?: (ratings: number[]) => void;
}

const RatingFilter: React.FC<RatingFilterProps> = (props) => {
  const [value, setValue] = useState<number[]>(props.value ?? []);

  const toggleRating = (rating: number) => {
    const newValue = value.includes(rating)
      ? value.filter((n) => n !== rating)
      : [...value, rating].sort();

    setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  useEffect(() => {
    setValue(props.value ?? []);
  }, [props.value]);

  return (
    <>
      <div className="toggle-buttons toggle-buttons--filter">
        {[5, 4, 3, 2, 1].map((i) => (
          <button
            type="button"
            className={buildClassName(["button", value.includes(i) && "button--active"])}
            onClick={() => toggleRating(i)}
            key={i}
          >
            <Icon name="product-star" />
            {i}
          </button>
        ))}
      </div>
    </>
  );
};

export default RatingFilter;
