import ProductCard from "../../components/product-card";
import React from "react";
import { SearchResult } from "./utils/search-util";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ResultProps {
  searchResult: SearchResult;
}

const Result: React.FC<ResultProps> = ({ searchResult }) => {
  const { t } = useI18next();

  return (
    <ProductCard
      cardType="hotel"
      title={searchResult.title ?? ""}
      imageSrc={searchResult.image?.url ?? undefined}
      rating={searchResult.rating}
      description={searchResult.subtitle}
      price={{ defaultPrice: searchResult.price }}
      linkText={searchResult.productType == 3 ? t("VIEW_THIS_HOTEL") : t("VIEW_THIS_ROUNDTRIP")}
      linkUrl={searchResult.url}
      hasFlight={searchResult.hasFlight}
      hasTransfer={searchResult.hasTransfer}
      isNotAvailable={searchResult.isNotAvailable}
      onRequest={searchResult.onRequest}
      isSearchResult={true}
      minStay={searchResult.minStay}
      locationNames={searchResult.countryName ? [searchResult.countryName] : []}
      productType={searchResult.productType}
    />
  );
};

export default Result;
