import React, { useEffect, useState } from "react";
import { TideItemForTheme, TideItemForUsp } from "../../types";
import useMediaQuery from "../../useMediaQuery";
import { buildClassName } from "../../utils";
import PriceRangeFilter from "./price-filter";
import RatingFilter from "./rating-filter";
import { useFilterStore } from "./filter-store/context";
import {
  clearAll,
  setMaxPrice,
  setMinPrice,
  setPendingFilter,
  setProductTypes,
  setRatings,
  setRegimeCodes,
  toggleTheme,
  toggleUsp,
} from "./filter-store/reducer";
import ToggleFilter from "./toggle-filter";
import ProductTypeFilter from "./product-type-filter";
import regimeData from "../../../static/regimes.json";
import { isEqual } from "lodash";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface FilterListProps {
  themes: TideItemForTheme[];
  usps: TideItemForUsp[];
}

interface Regime {
  code: string;
  otherCodes: string[];
  translationKey: string;
}

const FilterList: React.FC<FilterListProps> = (props) => {
  const { t } = useTranslation();
  const [state, dispatch] = useFilterStore();
  const [active, setActive] = useState<boolean>(false);
  const [selectedRegimes, setSelectedRegimes] = useState<string[]>([]);
  const [selectedMinPrice, setSelectedMinPrice] = useState<number>();
  const [selectedMaxPrice, setSelectedMaxPrice] = useState<number>();

  const mediaLG = useMediaQuery("(min-width: 1200px)", (matches) => {
    if (matches) {
      setActive(false);

      if (typeof document !== "undefined") {
        document.body.classList.remove("has-overlay");
      }
    }
  });

  const handleHeaderClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();

    if (!mediaLG) {
      const newState = !active;
      setActive(newState);

      if (typeof document !== "undefined") {
        document.body.classList.toggle("has-overlay", newState);
      }
    }
  };

  const handleResetClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (dispatch) {
      dispatch(clearAll());
    }
  };

  const handleOnToggleRegime = (regime: Regime, checked: boolean) => {
    const regimeCodes = regime.otherCodes.concat(regime.code);
    if (checked) {
      setSelectedRegimes(selectedRegimes.concat(regimeCodes));
    } else {
      setSelectedRegimes(selectedRegimes.filter((x) => !regimeCodes.includes(x)));
    }
  };

  useEffect(() => {
    if (
      !isEqual(selectedMaxPrice, state?.maxPrice) ||
      !isEqual(selectedMinPrice, state?.minPrice) ||
      !isEqual(selectedRegimes, state?.regimeCodes)
    ) {
      if (dispatch) dispatch(setPendingFilter(true));
    } else {
      if (dispatch) dispatch(setPendingFilter(false));
    }
  }, [selectedMinPrice, selectedMaxPrice, selectedRegimes]);

  return (
    <form
      className={buildClassName([
        "faceted-search__filters",
        active && "faceted-search__filters--active",
      ])}
    >
      <div className="faceted-search__filters-header" onClick={handleHeaderClick}>
        <h3 className="faceted-search__filters-heading">{t("PREFERENCES")}</h3>
        <button type="reset" title="Wis voorkeuren" className="link" onClick={handleResetClick}>
          {t("DELETE_PREFERENCES")}
        </button>
      </div>

      <div className="faceted-search__filter-group">
        <h5 className="faceted-search__filter-heading">{t("HOLIDAY_TYPE")}</h5>
        <ProductTypeFilter
          value={state?.productTypes}
          onChange={(productTypes) => dispatch && dispatch(setProductTypes(productTypes))}
        />
      </div>

      <div className="faceted-search__filter-group">
        <h5 className="faceted-search__filter-heading">{t("RATING")}</h5>
        <RatingFilter
          value={state?.ratings}
          onChange={(ratings) => dispatch && dispatch(setRatings(ratings))}
        />
      </div>

      <div className="faceted-search__filter-group">
        <h5 className="faceted-search__filter-heading">{t("BUDGET")}</h5>
        <PriceRangeFilter
          minPrice={selectedMinPrice}
          maxPrice={selectedMaxPrice}
          onMinPriceChange={(minPrice) => setSelectedMinPrice(minPrice)}
          onMaxPriceChange={(maxPrice) => setSelectedMaxPrice(maxPrice)}
        />
        {(!isEqual(state?.minPrice, selectedMinPrice) ||
          !isEqual(state?.maxPrice, selectedMaxPrice)) && (
          <button
            className="cta"
            id="faceted-search__apply-price"
            title={t("APPLY_PREFERENCES")}
            onClick={() => {
              dispatch && dispatch(setMinPrice(selectedMinPrice));
              dispatch && dispatch(setMaxPrice(selectedMaxPrice));
            }}
          >
            {t("APPLY")}
          </button>
        )}
      </div>

      {regimeData.regimes && (
        <div className="faceted-search__filter-group">
          <h5 className="faceted-search__filter-heading">{t("REGIMES")}</h5>
          {regimeData.regimes.map((regime) => (
            <ToggleFilter
              key={regime.code}
              text={t(regime.translationKey, { ns: "regimes" })}
              checked={selectedRegimes.includes(regime.code)}
              onToggle={(checked) => handleOnToggleRegime(regime, checked)}
            />
          ))}
          {!isEqual(state?.regimeCodes, selectedRegimes) && (
            <button
              className="cta"
              id="faceted-search__apply-regimes"
              title={t("APPLY_PREFERENCES")}
              onClick={() => dispatch && dispatch(setRegimeCodes(selectedRegimes))}
            >
              {t("APPLY")}
            </button>
          )}
        </div>
      )}

      <div className="faceted-search__filter-group">
        <h5 className="faceted-search__filter-heading">{t("THEMES")}</h5>
        {props.themes.map((theme) => (
          <ToggleFilter
            key={theme.id}
            text={theme.content?.general?.title ?? theme.name}
            checked={state?.themes.includes(theme.id)}
            onToggle={(checked) => dispatch && dispatch(toggleTheme(theme.id, checked))}
          />
        ))}
      </div>

      <div className="faceted-search__filter-group">
        <h5 className="faceted-search__filter-heading">{t("UPS")}</h5>
        {props.usps.map((usp) => (
          <ToggleFilter
            key={usp.id}
            text={usp.content?.general?.text ?? usp.name}
            checked={state?.usps.includes(usp.id)}
            onToggle={(checked) => dispatch && dispatch(toggleUsp(usp.id, checked))}
          />
        ))}
      </div>

      <div className="faceted-search__filters-actions">
        <button
          // type="submit"
          className="cta"
          id="faceted-search__apply-preferences"
          title={t("APPLY_PREFERENCES")}
          onClick={() => setActive(false)}
        >
          {t("APPLY_PREFERENCES")}
        </button>
      </div>
    </form>
  );
};

export default FilterList;
