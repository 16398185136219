import { SearchResult } from "./search-util";
import { isNil, orderBy } from "lodash";
import { SortOrder, FilterState } from "../filter-store/reducer";

interface Order {
  columns: string[];
  type: (boolean | "asc" | "desc")[];
}

export const filter = (
  state?: FilterState,
  searchResults?: SearchResult[]
): SearchResult[] | undefined => {
  if (!state || !searchResults) {
    return undefined;
  }

  // if (!isNil(state.maxPrice) && !isNaN(state.maxPrice)) {
  //   searchResults = searchResults.filter(
  //     (result) => typeof result.price !== "undefined" && result.price <= state.maxPrice!
  //   );
  // }

  // if (!isNil(state.minPrice) && !isNaN(state.minPrice)) {
  //   console.log('filter-util price');
  //   searchResults = searchResults.filter(
  //     (result) => typeof result.price !== "undefined" && result.price > state.minPrice!
  //   );
  // }

  if (state.ratings.length > 0) {
    searchResults = searchResults.filter((result) =>
      state.ratings.some((x) => result.rating! >= x && result.rating! < x + 1)
    );
  }

  if (state.usps) {
    searchResults = searchResults.filter((result) =>
      state.usps.every((uspId) => result.uspIds.includes(uspId))
    );
  }

  if (state.themes) {
    searchResults = searchResults.filter((result) =>
      state.themes.every((themeId) => result.themeIds.includes(themeId))
    );
  }

  searchResults = searchResults.filter((result) =>
    state.productTypes.some((x) => x === result.productType)
  );

  // Sort
  const order = getOrder(state?.sortOrder);
  const sortedSearchResults = orderBy(searchResults, order.columns, order.type);

  return sortedSearchResults;
};

function getOrder(order?: SortOrder): Order {
  switch (order) {
    case "priceAsc":
      return <Order>{
        columns: ["price"],
        type: ["asc"],
      };
    case "priceDesc":
      return <Order>{
        columns: ["price"],
        type: ["desc"],
      };
    case "departureAsc":
    default:
      return <Order>{
        columns: ["from", "title"],
        type: ["asc", "asc"],
      };
  }
}
