import {
  TideItemConnection,
  TideItemForDestinations,
  TideItemForHotelConnection,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForPage,
  TideItemForRoundtripConnection,
  TideItemForTheme,
  TideItemForUsp,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";
import ContactBubble from "../components/contact-bubble";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import PageComponents from "../components/page-components";
import React from "react";
import SearchResults from "../app/search-results";
import StickyBar from "../components/sticky-bar";
import { compact, first, isEmpty, uniqBy } from "lodash";
import { getChildNavigationLinks } from "../utils";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";

interface SearchPageTemplateProps {
  data: SearchPageQuery;
  pageContext: {
    language: string;
    alternateRoutes: AlternateRoute[];
  };
}

const SearchPageTemplate: React.FC<SearchPageTemplateProps> = ({ data, pageContext }) => {
  const generalPageContent = data.page?.content?.general;
  const title = compact([generalPageContent?.titlePrefix, generalPageContent?.title]).join(" ");
  const notificationBar = first(data.notifications?.nodes);

  return (
    <Layout>
      <Seo
        title={title}
        seoTitle={data.page.content?.seo?.seoTitle ?? undefined}
        seoDescription={data.page.content?.seo?.seoDescription ?? undefined}
        seoKeywords={data.page.content?.seo?.seoKeywords ?? undefined}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext?.alternateRoutes}
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      <StickyBar navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)} />
      <Hero
        type="search-results"
        title={generalPageContent?.title ?? undefined}
        titlePrefix={generalPageContent?.titlePrefix ?? undefined}
        imageSrc={generalPageContent?.headerImage?.url ?? undefined}
      />
      <SearchResults
        searchPagePath={data.page.content?.general?.slug ?? undefined}
        hotels={data.hotels.nodes}
        roundtrips={data.roundtrips.nodes}
        themes={
          uniqBy(
            [...data.roundtrips.nodes, ...data.hotels.nodes].flatMap(
              (node) => node.content?.general?.themes
            ),
            (theme) => theme?.id
          ) as TideItemForTheme[]
        }
        usps={
          uniqBy(
            [...data.roundtrips.nodes, ...data.hotels.nodes].flatMap(
              (node) => node.content?.general?.usps
            ),
            (usp) => usp?.id
          ) as TideItemForUsp[]
        }
      />
      <PageComponents componentItems={compact(data.page.childItems)} />
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
      <ContactBubble contactNavigationSection={data.contactNavigationSection} />
    </Layout>
  );
};

interface SearchPageQuery extends LookupQueryData {
  page: TideItemForPage;
  breadcrumb: TideItemConnection;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
  hotels: TideItemForHotelConnection;
  roundtrips: TideItemForRoundtripConnection;
}

export const query = graphql`
  query SearchPageQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: tideItemForSearchPage(id: { eq: $id }) {
      id
      content {
        general {
          title
          titlePrefix
          slug
          headerImage {
            url
          }
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      ...pageComponentsFragment
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
    hotels: allTideItemForHotel(filter: { language: { eq: $language } }) {
      nodes {
        ...searchResults
      }
    }
    roundtrips: allTideItemForRoundtrip(filter: { language: { eq: $language } }) {
      nodes {
        ...searchResults
      }
    }
  }

  fragment fullscreenNavigationSectionFields on TideItemForNavigationSection {
    id
    childItems {
      id
      templateName
      ... on TideItemForNavigationSection {
        content {
          general {
            title
            text
            path
            url
          }
        }
        childItems {
          ...navigationLinkFields
        }
      }
    }
  }

  fragment navigationLinkFields on TideItemForNavigationLink {
    id
    templateName
    name
    content {
      general {
        title
        icon
        path
        url
      }
    }
  }

  fragment uspGroupFields on TideItemForUspGroup {
    id
    content {
      general {
        title
      }
    }
    childItems {
      id
      itemId
      templateName
      ... on TideItemForUsp {
        content {
          general {
            icon
            text
          }
        }
      }
    }
  }
`;

export default SearchPageTemplate;
