import React, { useState, useEffect } from "react";
import {
  TideItemForHotel,
  TideItemForRoundtrip,
  TideItemForTheme,
  TideItemForUsp,
} from "../../types";
import FilterList from "./filter-list";
import QSM from "./qsm";
import { sortProps } from "./qsm/utils";
import Results from "./results";
import Loader from "../../components/loader";
import { useQSMStore } from "./qsm-store/context";
import { useFilterStore } from "./filter-store/context";
import { setCountryId, setRegionId } from "./qsm-store/reducer";
import { filter } from "./utils/filter-util";
import { searchProducts, SearchResult } from "./utils/search-util";
import { setPendingFilter } from "./filter-store/reducer";
import { useI18next } from "gatsby-plugin-react-i18next";

interface FacetedSearchProps {
  hotels: TideItemForHotel[];
  roundtrips: TideItemForRoundtrip[];
  themes: TideItemForTheme[];
  usps: TideItemForUsp[];
  searchPagePath?: string;
}

const FacetedSearch: React.FC<FacetedSearchProps> = ({
  hotels,
  roundtrips,
  themes,
  usps,
  searchPagePath,
}) => {
  const [qsmState, qsmDispatch] = useQSMStore();
  const [filterState, filterDispatch] = useFilterStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchResult[] | undefined>(undefined);
  const [shouldReload, setShouldReload] = useState<boolean>(false);
  const { t, language } = useI18next();
  const fetchData = async () => {
    if (qsmState?.loaded && (!searchResults || filterState?.pendingFilter)) {
      setIsLoading(true);
      let token: string | null = null;
      if (typeof sessionStorage !== "undefined") {
        token = sessionStorage.getItem("token");
      }
      const _searchResults = await searchProducts(
        {
          country: qsmState?.countryId,
          region: qsmState?.regionId,
          searchGlobal:
            typeof qsmState?.countryId === "undefined" && typeof qsmState?.regionId === "undefined",
          from: qsmState?.dateRange?.fromDate?.toISOString(),
          to: qsmState?.dateRange?.toDate?.toISOString(),
          rooms: qsmState?.rooms,
          regimeCodes: filterState?.regimeCodes,
          minPrice: filterState?.minPrice,
          maxPrice: filterState?.maxPrice,
        },
        hotels,
        roundtrips,
        t,
        language,
        token
      );
      setSearchResults(_searchResults);
      setIsLoading(false);
      if (filterDispatch) filterDispatch(setPendingFilter(false));
    }
  };

  const reload = () => {
    setSearchResults(undefined);
    setShouldReload(!shouldReload); // Only change value to trigger useEffect.
  };

  useEffect(() => {
    fetchData();
  }, [
    qsmState?.loaded,
    qsmState?.countryId,
    qsmState?.regionId,
    qsmState?.dateRange?.fromDate?.valueOf(),
    qsmState?.dateRange?.toDate?.valueOf(),
    JSON.stringify(qsmState?.rooms?.map(sortProps)),
    hotels,
    roundtrips,
    filterState?.regimeCodes,
    filterState?.minPrice,
    filterState?.maxPrice,
    shouldReload,
  ]);

  const filteredSearchResults = filter(filterState, searchResults);
  return (
    <section className="faceted-search">
      <div className="backdrop" id="backdrop"></div>
      <FilterList themes={themes} usps={usps} />
      <div className="faceted-search__results">
        <QSM
          reload={reload}
          searchPagePath={searchPagePath}
          onDestinationChange={(id, locationType) => {
            if (qsmDispatch) {
              if (locationType === "country") {
                qsmDispatch(setCountryId(id));
              } else if (locationType === "region") {
                qsmDispatch(setRegionId(id));
              }
            }
          }}
        />
        {!isLoading && (
          <Results
            searchResults={filteredSearchResults}
            hasDestination={!!qsmState?.countryId || !!qsmState?.regionId}
          />
        )}
        {isLoading && <Loader />}
      </div>
    </section>
  );
};

export default FacetedSearch;
