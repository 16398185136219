import { useEffect, useState } from "react";

const useMediaQuery = (mediaQuery: string, onChange?: (matches: boolean) => void) => {
  if (typeof window !== "undefined" && "matchMedia" in window) {
    const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches);

    useEffect(() => {
      const mediaQueryList = window.matchMedia(mediaQuery);
      const documentChangeHandler = () => {
        setIsVerified(!!mediaQueryList.matches);

        if (onChange) {
          onChange(!!mediaQueryList.matches);
        }
      };

      mediaQueryList.addEventListener("change", documentChangeHandler);

      documentChangeHandler();
      return () => {
        mediaQueryList.removeEventListener("change", documentChangeHandler);
      };
    }, [mediaQuery, onChange]);

    return isVerified;
  }
};

export default useMediaQuery;
