import React from "react";

interface ToggleFilterProps {
  text: string;
  amount?: number;
  checked?: boolean;
  onToggle?: (checked: boolean) => void;
}

const ToggleFilter: React.FC<ToggleFilterProps> = ({ text, amount, checked, onToggle }) => {
  const handleToggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onToggle) {
      onToggle(e.target.checked);
    }
  };

  return (
    <label className="checkbox checkbox--reverse">
      <input
        type="checkbox"
        name="checkbox-filter"
        id="checkbox-filter"
        className="checkbox__input"
        checked={checked}
        onChange={handleToggle}
      />
      <span>{text}</span>
      <em className="amount-label">{amount}</em>
    </label>
  );
};

export default ToggleFilter;
