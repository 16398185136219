import React from "react";
import Result from "./result";
import { SearchResult } from "./utils/search-util";
import Sorter from "./sorter";
import { setSortOrder } from "./filter-store/reducer";
import { useFilterStore } from "./filter-store/context";
import { isEmpty } from "lodash";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ResultsProps {
  searchResults?: SearchResult[];
  hasDestination?: boolean;
}

const Results: React.FC<ResultsProps> = ({ searchResults, hasDestination }) => {
  const [state, dispatch] = useFilterStore();
  const { t } = useI18next();

  const itemsOnRequest = searchResults?.filter((x) => !x.isNotAvailable && x.onRequest);
  const itemsAvailable = searchResults?.filter((x) => !x.isNotAvailable && !x.onRequest);
  const itemsNotAvailable = searchResults?.filter((x) => x.isNotAvailable);

  return (
    <>
      {!isEmpty(itemsAvailable) && (
        <section className="section">
          <div className="productcard-grid productcard-grid--faceted-search">
            <div className="faceted-search__results-header">
              <h3 className="faceted-search__results-heading">
                {itemsAvailable && itemsAvailable.length == 1
                  ? t("RESULT_COUNT")
                  : t("RESULTS_COUNT", {
                      numberOfResults: itemsAvailable && itemsAvailable.length,
                    })}
              </h3>

              <Sorter
                order={state?.sortOrder}
                onChange={(order) => dispatch && dispatch(setSortOrder(order))}
              />
            </div>

            <div className="productcard-grid__items">
              {itemsAvailable &&
                itemsAvailable.map((searchResult) => (
                  <Result key={searchResult.key} searchResult={searchResult} />
                ))}
            </div>
          </div>
        </section>
      )}
      {!isEmpty(itemsOnRequest) && (
        <section className="section">
          <div className="productcard-grid productcard-grid--faceted-search">
            <div className="faceted-search__results-header">
              <h3 className="faceted-search__results-heading">
                {itemsOnRequest && itemsOnRequest.length == 1
                  ? t("RESULT_ONREQUEST_COUNT")
                  : t("RESULTS_ONREQUEST_COUNT", {
                      numberOfResults: itemsOnRequest && itemsOnRequest.length,
                    })}
              </h3>

              <Sorter
                order={state?.sortOrder}
                onChange={(order) => dispatch && dispatch(setSortOrder(order))}
              />
            </div>

            <div className="productcard-grid__items">
              {itemsOnRequest &&
                itemsOnRequest.map((searchResult) => (
                  <Result key={searchResult.key} searchResult={searchResult} />
                ))}
            </div>
          </div>
        </section>
      )}
      {!isEmpty(itemsNotAvailable) && hasDestination && (
        <section className="section">
          <div className="productcard-grid productcard-grid--faceted-search">
            <div className="faceted-search__results-header">
              <h3 className="faceted-search__results-heading">
                {itemsNotAvailable && itemsNotAvailable.length == 1
                  ? t("RESULT_NOT_AVAILABLE_COUNT")
                  : t("RESULTS_NOT_AVAILABLE_COUNT", {
                      numberOfResults: itemsNotAvailable && itemsNotAvailable.length,
                    })}
              </h3>

              <Sorter
                order={state?.sortOrder}
                onChange={(order) => dispatch && dispatch(setSortOrder(order))}
              />
            </div>

            <div className="productcard-grid__items">
              {itemsNotAvailable &&
                itemsNotAvailable.map((searchResult) => (
                  <Result key={searchResult.key} searchResult={searchResult} />
                ))}
            </div>
          </div>
        </section>
      )}
      {!isEmpty(itemsNotAvailable) && !hasDestination && (
        <section className="section">
          <div className="productcard-grid productcard-grid--faceted-search">
            <div className="faceted-search__results-header">
              <h3 className="faceted-search__results-heading">
                {itemsNotAvailable && itemsNotAvailable.length == 1
                  ? t("RESULT_COUNT")
                  : t("RESULTS_COUNT", {
                      numberOfResults: itemsNotAvailable && itemsNotAvailable.length,
                    })}
              </h3>

              <Sorter
                order={state?.sortOrder}
                onChange={(order) => dispatch && dispatch(setSortOrder(order))}
              />
            </div>

            <div className="productcard-grid__items">
              {itemsNotAvailable &&
                itemsNotAvailable.map((searchResult) => (
                  <Result key={searchResult.key} searchResult={searchResult} />
                ))}
            </div>
          </div>
        </section>
      )}
      {isEmpty(searchResults) && (
        <section className="section">
          <div className="productcard-grid productcard-grid--faceted-search">
            <div className="faceted-search__results-header">
              <h3 className="faceted-search__results-heading">{t("NO_RESULTS")}</h3>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Results;
